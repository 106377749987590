import "./toastify";

let newsletter = document.getElementById("js-newsletter");
let newsletterButton = newsletter.querySelector("button");

newsletter.onsubmit = function(e) {
    e.preventDefault();

    newsletterButton.disabled = true;
    newsletterButton.classList.add("disabled");

    let data = new FormData(newsletter);
    let xhr = new XMLHttpRequest();

    xhr.open(newsletter.method, newsletter.action);
    xhr.send(data);

    xhr.onloadend = function(response) {
        if (response.target.status === 0) {
            // Failed XmlHttpRequest should be considered an undefined error.
            showToast(newsletter.dataset.formError, "error");
        } else if (response.target.status === 400) {
            // Bad Request
            showToast(JSON.parse(responseText).error, "error");
        } else if (response.target.status === 200) {
            const result = JSON.parse(response.target.response);
            if (result.status) {
                showToast(result.msg, "success");
                newsletter.reset();
            } else {
                showToast(result.msg, "error");
            }
        }
        newsletterButton.disabled = false;
        newsletterButton.classList.remove("disabled");
    };
};
